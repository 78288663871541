<template>
  <div class="home">
    <div class="banner">
      <img src="../assets/image/home/banner.png" alt="" />
      <div class="countdown">
        <img src="../assets/image/home/MysteryBoxOpen.png" alt="" />
        <countdown
          ref="countdown"
          :time="
            parseInt(
              (
                new Date('2022-12-03 22:00').getTime() - new Date().getTime()
              ).toFixed(0)
            )
          "
          format="HH:mm:ss"
          :auto-start="true"
        >
          <template #="{ resolved }">
            <span class="countdown-item">{{ resolved.HH }}</span> :
            <span class="countdown-item">{{ resolved.mm }}</span> :
            <span class="countdown-item">{{ resolved.ss }}</span>
          </template>
        </countdown>
      </div>
      <div class="mint">
        <div class="description">
          <p>Max 4 per Wallet</p>
        </div>
        <div class="progress">
          <div ref="progress"></div>
        </div>

        <div class="button-group">
          <div class="sub" @click="sub"></div>
          <div class="button">
            <div class="input">
              <el-input-number
                v-model="quantity"
                :min="1"
                :max="4"
                :controls="false"
                :precision="0"
                size="large"
              />
            </div>
            <b-button
              class="primary mint-button"
              @click="mint"
              :loading="isConnect && (isLoading || buttonLoading)"
              :disabled="(isConnect && isLoading) || buttonLoading"
              >FREE MINT</b-button
            >
          </div>
          <div class="add" @click="add"></div>
        </div>
      </div>
    </div>

    <div class="show-card">
      <div class="show-border">
        <div class="show-view">
          <div class="show-box">
            <div>
              <marquee class="d-flex align-items-center" scrollamount="3"
                ><font
                  v-for="(item, index) in randArray"
                  :key="index"
                  size="+1"
                  color="white"
                  style="padding: 0 10px"
                  >{{ item }}</font
                ></marquee
              >
            </div>
          </div>
          <div id="ABOUT" class="show-box mt-4">
            <div>
              <marquee
                class="d-flex align-items-center"
                scrollamount="3"
                behavior="scroll"
                contenteditable="true"
                ><font
                  v-for="(item, index) in randArray1"
                  :key="index"
                  size="+1"
                  color="white"
                  style="padding: 0 10px"
                  >{{ item }}</font
                ></marquee
              >
            </div>
          </div>
          <div class="show-text">
            <p class="title">LET WORLD CUP BE THE START OF AZUGOAL</p>
            <p class="title"><span>880</span> ETH TOTAL PRIZE!</p>
            <p>
              Enter the Tokenline Game and bet on the champion of 2022 World
              Cup.
            </p>
            <br />
            <p>
              *You will see your NFT number on board after finishing the bet.
            </p>
          </div>
        </div>
      </div>
      <div class="link">
        <p>WHITE PAPER<br />We are proud of showing Our contract here</p>
        <b-link
          class="primary"
          target="_blank"
          href="https://etherscan.io/address/0xd0309b3F8006B25d8ea1214d989A234139a2E25E#code"
          >Etherscan</b-link
        >
      </div>
      <div class="text">
        <p class="title">AzuGoal Football Fantasy</p>
        <p class="subtitle">Passions from AZUKI HODLers</p>
        <p class="mb-4">
          Players from parallel AzuGoal Universe participate in a football
          journey, representing differentteams on earth due to a time warp that
          connects two worlds of different dimensions.
        </p>

        <p class="mb-4">
          As the Golden Cup Champion of 2022 FIFA Qatar World Cup Digital Twin
          Arts Hackathon, and a 10K NFT series on Ethereum, AzuGoal is a
          combination of passion for football and cutting edge blockchain
          technology. We aim to connect the real world and AzuGoal Universe by
          gamification, and to create a system not just for NFT collection but
          also for users to equip their NFTs in Game and earn back from their
          NFTs.
        </p>

        <p class="mb-4">
          Being the genesis of our universe, AzuGoal Mystery Boxes will be
          released in the period of 2022 World Cup. Each one will contain
          AzuGoal NFTs belonging to a team in the 2022 Qatar World Cup. AzuGoal
          NFTs grant you access to the AzuGoal Universe where you can use to
          play multiple games, find your team and lift World Cup with your
          favorite team in AzuGoal Football Fantasy League.
        </p>

        <p class="mb-4">
          AzuGoal Football Fantasy Game and Tokens will be released as well. You
          will be able to use your AzuGoal NFT as character to build your own
          fantasy team with friends and earn tokens in AzuGoal Fantasy League.
        </p>

        <p class="mb-4">
          Acknowledgement: 2022 Qatar World Cup Digital Twin, OKX, XT, Blocklike
          etc.
        </p>
      </div>
    </div>
    <div class="content">
      <b-container>
        <b-row>
          <b-col cols="12" offset="0" sm="12" offset-sm="0">
            <img class="top" src="../assets/image/home/bg2-img1.png" alt="" />
          </b-col>
        </b-row>
        <b-row id="GAMEPLAY">
          <b-col cols="12" offset="0" sm="6" offset-sm="0">
            <div class="left">
              <span class="title">AirDrop Station</span>
              <span class="description"
                >Each time your team wins a game in the 2022 World Cup, you will
                get a airdrop including key, equipment or property. The Airdrops
                could be used to equip your character in the coming AzuGoal
                Football Fantasy Game.
              </span>
              <b-link class="jump-link" to="airdrop">ENTER></b-link>
            </div>
          </b-col>
          <b-col cols="12" offset="0" sm="6" offset-sm="0">
            <div class="right">
              <img class="" src="../assets/image/home/1.png" alt="" />
            </div>
          </b-col>
          <b-col cols="12" offset="0" sm="6" offset-sm="0">
            <div class="left">
              <span class="title">Champion Tokenline</span>
              <span class="description"
                >You can place bets on who will be the champion of 2022 Qatar
                World Cup. If you bet on the right team, you could share the
                <span>800eth</span> prize pool.</span
              >
              <b-link class="jump-link" to="champion">ENTER></b-link>
            </div>
          </b-col>
          <b-col cols="12" offset="0" sm="6" offset-sm="0">
            <div class="right">
              <img class="" src="../assets/image/home/2.png" alt="" />
            </div>
          </b-col>
          <b-col cols="12" offset="0" sm="6" offset-sm="0">
            <div class="left">
              <span class="title">Match Tokenline</span>
              <span class="description"
                >Starting from the Round of 16 (4th Dec), you will be able to
                place bets for each match. You can easily win ETH by betting on
                who will win the match.
              </span>
              <b-link class="coming-soon" :disabled="true"
                >COMING SOON ></b-link
              >
            </div>
          </b-col>
          <b-col cols="12" offset="0" sm="6" offset-sm="0">
            <div class="right">
              <img class="" src="../assets/image/home/3.png" alt="" />
            </div>
          </b-col>
          <b-col cols="12" offset="0" sm="6" offset-sm="0">
            <div class="left">
              <span class="title">Fantasy Game</span>
              <span class="description"
                >After the World Cup, AzuGoal Football Fantasy Game will be
                released. You can use your AzuGoal NFT in the game, team up with
                other AzuGoal Holders and earn tokens in the AzuGoal Football
                League.</span
              >
              <b-link class="coming-soon" :disabled="true"
                >COMING SOON ></b-link
              >
            </div>
          </b-col>
          <b-col cols="12" offset="0" sm="6" offset-sm="0">
            <div class="right">
              <img class="" src="../assets/image/home/4.png" alt="" />
            </div>
          </b-col>
          <b-col cols="12" offset="0" sm="6" offset-sm="0">
            <div class="left">
              <span class="title">AzuGoal Token</span>
              <span class="description"
                >Tokens could be used to buy equipment, enhance the ability of
                your AzuGoal character and do different things in AzuGoal
                Universe.</span
              >
              <b-link class="coming-soon" :disabled="true"
                >COMING SOON ></b-link
              >
            </div>
          </b-col>
          <b-col cols="12" offset="0" sm="6" offset-sm="0">
            <div class="right">
              <img class="" src="../assets/image/home/5.png" alt="" />
            </div>
          </b-col>
        </b-row>
        <b-row id="NFTs">
          <b-col cols="12" offset="0" sm="12" offset-sm="0">
            <img class="border-br" src="../assets/image/home/line.png" alt="" />
          </b-col>
        </b-row>
        <b-row class="nft">
          <b-col cols="12" offset="0" sm="3" offset-sm="0">
            <img class="" src="../assets/image/home/NFT1.png" alt="" />
          </b-col>
          <b-col cols="12" offset="0" sm="3" offset-sm="0">
            <img class="" src="../assets/image/home/NFT2.png" alt="" />
          </b-col>
          <b-col cols="12" offset="0" sm="3" offset-sm="0">
            <img class="" src="../assets/image/home/NFT3.png" alt="" />
          </b-col>
          <b-col cols="12" offset="0" sm="3" offset-sm="0">
            <img class="" src="../assets/image/home/NFT4.png" alt="" />
          </b-col>
          <b-col cols="12" offset="0" sm="12" offset-sm="0">
            <p class="title">WELCOME TO THE AZUGOAL WORLD</p>
            <p>
              AzuGoal is a series of support-to-earn NFT based on the football
              theme. We aim to connect the<br />
              real world and AzuGoal universe by gamifiction. When you buy an
              AzuGoal NFT, you are not only<br />
              buying a valuable and rare piece of art. You are getting a game
              pass grants you access to the<br />
              AzuGoal Fantasy Football Universe.
            </p>
            <p>
              The creative engine of AzuGoal is Atreus Assembly: led by Azuki
              HODLer #8719 and kept the originality<br />
              of Azuki HODLer #8719, is comprised of world-class illustrators
              and designers. Powered by passion <br />from AZUKI HOLDers, all
              the creative elements and illustrations of AzuGoal NFT were
              originally created.
            </p>
          </b-col>
        </b-row>
        <b-row id="TEAM" class="team">
          <p class="title">THE AZUGOAL FOUNDERS</p>
          <b-col cols="12" offset="0" sm="4" offset-sm="0">
            <img class="" src="../assets/image/home/team1.png" alt="" />
            <p>RAY#0</p>
            <p>Founding Member</p>
            <p>Project Manager</p>
            <p>Holder of AZUKI</p>
            <p>Support future IP development</p>
          </b-col>
          <b-col cols="12" offset="0" sm="4" offset-sm="0">
            <img class="" src="../assets/image/home/team2.png" alt="" />
            <p>0xWinningthePooh</p>
            <p>Chief Fire Starter of AzuGoal Club</p>
            <p>Kicking the ball at the right time!</p>
            <p>Forbes 30U30, Day 1 HODLer of BAYC, Azuki</p>
          </b-col>
          <b-col cols="12" offset="0" sm="4" offset-sm="0">
            <img class="" src="../assets/image/home/team3.png" alt="" />
            <p>AtreusAssembly</p>
            <p>Master Creator of AzuGoal</p>
            <p>Anime viewed by millions of viewers</p>
            <p>
              Thanks Iwan, Yakult, CyberX, Fortune, HardCandy, Shumai, Kozi,
              Rick0x
            </p>
          </b-col>
        </b-row>
      </b-container>
      <b-row no-gutters class="footer">
        <b-col cols="12" offset="0" sm="12" offset-sm="0">
          <img class="" src="../assets/image/footer-logo.png" alt="" />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import NProgress from "nprogress";
import { mapState } from "vuex";
import { toRaw } from "vue";
import Countdown from "vue3-countdown";
export default {
  name: "HomeView",
  components: {
    Countdown,
  },
  computed: {
    ...mapState([
      "isConnect",
      "address",
      "contract",
      "abi",
      "contractAddress",
      "isShowTime",
      "isPublicMint",
      "isLoading",
    ]),
  },
  watch: {
    isConnect: {
      immediate: true,
      handler: async function (newval) {
        if (newval) {
          this.readGamblers();
          await this.readTotalSupply();
        }
      },
    },
  },
  data() {
    return {
      randArray: [],
      randArray1: [],
      quantity: 0,
      unit_price: 0,
      buttonLoading: false,
      totalSupply: 0,
      total: 9600,
      balance: 0,
      public: 4,
      time: 0,
    };
  },
  methods: {
    sub() {
      this.quantity -= 1;
    },
    add() {
      this.quantity += 1;
    },
    mint: async function () {
      if (!this.isConnect) {
        this.$message.error("Please connect your wallet first");
        return;
      }
      if (this.isPublicMint && !this.isShowTime) {
        if (this.balance + this.quantity > this.public) {
          return this.$message.error(
            "Sorry, The remaining number of Public Mints is " +
              (this.public - this.balance)
          );
        } else {
          let loadingWait = this.$message({
            type: "info",
            message:
              "Please wait patiently and do not close the page until the transaction is confirmed",
            duration: 0,
            center: true,
          });

          this.buttonLoading = true;
          try {
            let overrides = {
              gasLimit: null,
            };
            let gasLimit = await toRaw(
              this.contract
            ).estimateGas.publicSaleMint(this.quantity, overrides);
            console.log(gasLimit);
            overrides.gasLimit = gasLimit;
            let tx = await this.contract.publicSaleMint(
              this.quantity,
              overrides
            );
            await tx.wait();
            this.$message.success(
              "The transaction is successful. Please wait patiently for confirmation"
            );
            await this.readTotalSupply();
          } catch (error) {
            this.$message.error(error.reason ? error.reason : error.message);
          }
          this.buttonLoading = false;
          loadingWait.close();
        }
      } else {
        if (this.isShowTime) {
          return this.$message.error("Sorry, Mint is over");
        } else {
          return this.$message.error("Sorry, Public MINT has not started yet");
        }
      }
    },
    readTotalSupply: async function () {
      this.totalSupply = (await this.contract.totalSupply()).toString();
      this.balance = parseInt(
        (await this.contract.balanceOf(this.address)).toString()
      );
      console.log(this.balance);
      console.log(this.totalSupply);
      let percent = this.totalSupply / this.total;
      let positionX = this.$refs.progress.offsetWidth * percent;
      this.$refs.progress.style.backgroundPositionX =
        "-" + Math.ceil(positionX) + "px";
    },
    readGamblers: async function () {
      let gamblers = await this.contract.getGamblers();
      gamblers = [...gamblers];
      if (gamblers.length > 0) {
        let len = gamblers.length,
          index; // index: 随机数
        for (let point = len - 1; point >= 0; point--) {
          index = Math.floor(Math.random() * point);
          // 交换两个元素的值(在上一篇介绍过几种方法)
          gamblers[index] = [
            gamblers[point],
            (gamblers[point] = gamblers[index]),
          ][0];
        }
        const middleIndex = Math.ceil(gamblers.length / 2);
        this.randArray = [
          ...this.randArray,
          ...gamblers.splice(0, middleIndex),
        ]; // returns [1, 2, 3]
        this.randArray1 = [
          ...this.randArray1,
          ...gamblers.splice(-middleIndex),
        ];
      }
    },
  },
  mounted: async function () {
    // let randArray = Array.from(
    //   { length: 20 },
    //   () => Math.floor(Math.random() * (1000 - 0)) + 0
    // );
    // console.log(randArray);
    // let len = randArray.length,
    //   index; // index: 随机数
    // for (let point = len - 1; point >= 0; point--) {
    //   index = Math.floor(Math.random() * point);
    //   // 交换两个元素的值(在上一篇介绍过几种方法)
    //   randArray[index] = [
    //     randArray[point],
    //     (randArray[point] = randArray[index]),
    //   ][0];
    // }
    // const middleIndex = Math.ceil(randArray.length / 2);
    // this.randArray = randArray.splice(0, middleIndex); // returns [1, 2, 3]
    // this.randArray1 = randArray.splice(-middleIndex);
    this.$nextTick(() => {
      setTimeout(() => {
        NProgress.done();

        document.getElementById("app").style.visibility = "visible";
        // 关闭进度条
        // NProgress.done();
      }, 2000);
    });
  },
};
</script>
<style scoped lang="less">
.banner {
  position: relative;
  width: 100%;
  background: #000;
  img {
    width: 100%;
  }
  .countdown {
    position: absolute;
    top: 18%;
    left: 38.5%;
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 50%;
      margin-bottom: 9%;
    }

    div {
      width: 100%;
      font-size: 40px;
      font-weight: bold;
      color: #fff;
      background: #000;
      text-align: center;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;
      align-items: center;
      span {
        display: block;
        padding: 4% 10%;
      }
    }
  }
  .mint {
    width: 30%;
    margin: 0 auto;
    position: absolute;
    bottom: 10%;
    left: 34%;
    .description {
      p {
        color: white;
        font-size: 18px;
        font-weight: bold;
        word-spacing: 2px;
        margin-bottom: 2.5%;
      }
      margin-bottom: 0;
    }
    .progress {
      width: 100%;
      background: url("../assets/image/home/progress-border.png") no-repeat;
      background-size: 100% 100%;
      padding: 3.5% 3% 8.1%;
      margin: 0 auto;
      div {
        width: 100%;
        height: 100%;
        background: url("../assets/image/home/progress.png") no-repeat;
        background-size: 100% 100%;
        background-position-x: 0px;
        transition: background 1s linear;
        padding: 0.2% 0 4.5%;
      }
    }

    .button-group {
      display: flex;
      justify-content: center;
      align-items: end;
      margin-top: 6%;
      .sub {
        width: 3rem;
        height: 3rem;
        background: url("../assets/image/home/-.png") no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
      }
      .button {
        background: url("../assets/image/home/mint-button.png") no-repeat;
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 30px;
        width: 160px;
        height: 115px;
        .input {
          width: 3rem;
          height: 3rem;
          background: none;
          margin-right: 11px;
          .el-input-number {
            width: 100%;
            height: 100%;

            padding: 0;
            border: none;
            /deep/.el-input__wrapper {
              box-shadow: none;
              padding: 0;
              background: none;
              input {
                font-size: 22px;
                text-align: center;
                line-height: 3rem;
                color: #fff;
                font-weight: bold;
              }
            }
          }
        }
        .mint-button {
          font-size: 20px;
          margin-top: 40px;
          width: 100%;
          height: 50px;
          color: #a1119f;
          font-weight: bold;
          background: none;
          line-height: 25px;
          text-align: center;
          padding: 0;
        }
      }

      .add {
        width: 3rem;
        height: 2.8rem;
        background: url("../assets/image/home/+.png") no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
      }
    }
  }
}
.show-card {
  width: 100%;
  padding: 0 5rem 5rem;
  background: url("../assets/image/home/bg1.png") no-repeat;
  background-size: 100% 100%;
  .show-border {
    width: 85%;
    background: url("../assets/image/home/bg1-border.png") no-repeat;
    background-size: 100% 100%;
    padding: 12.5rem 9.5rem;
    margin: 0 auto;
    .show-view {
      width: 95%;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .show-box {
        width: 95%;
        padding: 0 16px;
        height: 47px;
        background: url("../assets/image/home/bg1-show-border.png") no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        div {
          width: 92%;
          height: 80%;
          marquee {
            height: 100%;
          }
        }
      }
      .show-text {
        margin-top: 45px;
        p {
          font-size: 15px;
          color: #73f5d6;
          margin-bottom: 5px;
          display: inline-block;
        }
        p.title {
          font-size: 25px;
          color: #76ffdd;
          // margin-top: 10px;
          margin-bottom: 20px;
          font-family: GILSANUB;
          background: none;
          font-weight: bold;
          display: block;
        }
        p.title:nth-child(2) {
          color: #fae10c;
          span {
            font-size: 50px;
            font-family: GILSANUB;
          }
        }
      }
    }
  }

  .text {
    width: 73%;
    margin: 8rem auto 0;
    p {
      font-size: 16px;
      color: #fff;
      font-weight: bold;
      line-height: 24px;
      word-spacing: 1px;
      text-align: justify;
      width: 80%;
      margin: 0 auto;
    }
    p.title {
      font-size: 40px;
      color: #76ffdd;
      font-family: GILSANUB;
      text-align: center;
      margin-bottom: 25px;
    }
    p.subtitle {
      font-size: 24px;
      color: #76ffdd;
      margin-bottom: 40px;
      font-family: GILSANUB;
      text-align: center;
    }
  }

  .link {
    p {
      font-size: 15px;
      color: #fff;
      margin: 0;
    }
  }
}
.content {
  width: 100%;
  padding: 5rem 0 0;
  background: url("../assets/image/home/bg11.png") no-repeat;
  background-size: 100% 98%;

  .row {
    margin-bottom: 40px;
    .top {
      width: 78%;
      margin-bottom: 120px;
    }
    .col {
      margin-bottom: 20px;
      .left {
        display: flex;
        flex-direction: column;
        text-align: justify;
        margin-left: 30px;
        span {
          font-size: 16px;
          color: #fff;
          font-weight: bold;
          margin-bottom: 10px;
          width: 80%;
          line-height: 30px;
          span {
            color: #fae10c;
            font-size: 25px;
          }
        }
        span.title {
          font-size: 32px;
          margin-bottom: 45px;
          margin-top: 90px;
        }
        a {
          color: #76ffdd;
          text-decoration: none;
          font-weight: bold;
          font-size: 18px;
          letter-spacing: 0.5px;
        }
        a.coming-soon {
          color: #454545;
        }
      }
      .right {
        img {
          width: 95%;
        }
      }

      .border-br {
        width: 100%;
        height: 3px;
      }
    }
  }
  .row.nft {
    p {
      color: #fff;
      font-size: 16px;
      width: 85%;
      margin: 0 auto;
      letter-spacing: 0.9px;
      font-weight: bold;
      line-height: 24px;
    }
    p:nth-child(3) {
      margin-top: 30px;
    }
    p.title {
      font-family: GILSANUB;
      color: #76ffdd;
      font-size: 27px;
      margin: 60px auto 50px;
    }
  }

  .row.team {
    img {
      margin-bottom: 30px;
    }
    p {
      width: 80%;
      color: #fff;
      margin: 0px auto 10px;
      word-wrap: break-word;
      font-weight: bold;
    }
    p.title {
      font-family: GILSANUB;
      color: #76ffdd;
      font-size: 27px;
      margin: 80px auto 50px;
    }
  }

  .row.footer {
    width: 100%;
    margin: 0;
    background: url("../assets/image/footer.png") no-repeat;
    background-size: 100% 100%;
    img {
      width: 40%;
      margin: 0 auto;
      padding: 50px 0;
    }
  }
}
.primary {
  width: 314px;
  height: 100px;
  line-height: 100px;
  display: inline-block;
  background: url("../assets/image/home/etherscan.png") no-repeat;
  background-size: 100% 100%;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: bolder;
  text-decoration: none;
  text-align: left;
  padding-left: 40px;
  color: #000;
}

@media (max-width: 576px) {
  .banner {
    width: 100%;
    position: static;
    background: #000;

    img {
      width: 100%;
    }
    .countdown {
      position: static;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      p {
        color: #fff;
        font-size: 18px;
        letter-spacing: 2px;
        font-weight: bold;
        padding: 5% 0 0 0;
      }
      div {
        width: 100%;
        font-size: 25px;
        font-weight: bold;
        color: #fff;
        background: #000;
        text-align: center;
        margin: 0 auto;
        display: flex;
        justify-content: space-around;
        align-items: center;
        span {
          display: block;
          padding: 3% 10%;
        }
      }
    }
    .mint {
      width: 80%;
      margin: 0 auto;
      position: static;
      padding: 50px 0 0 0;
      .progress {
        width: 100%;
        background: url("../assets/image/home/progress-border.png") no-repeat;
        background-size: 100% 100%;
        padding: 3.5% 3% 8.1%;
        margin: 0 auto;
        div {
          width: 100%;
          height: 100%;
          background: url("../assets/image/home/progress.png") no-repeat;
          background-size: 100% 100%;
          background-position-x: 0px;
          transition: background 1s linear;
          padding: 0.2% 0 4.5%;
        }
      }

      .button-group {
        display: flex;
        justify-content: center;
        align-items: end;
        margin-top: 15px;
        .sub {
          width: 35px;
          height: 35px;
          background: url("../assets/image/home/-.png") no-repeat;
          background-size: 100% 100%;
        }
        .button {
          background: url("../assets/image/home/mint-button.png") no-repeat;
          background-size: 100% 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 0 15px;
          width: 120px;
          height: 100px;
          .input {
            width: 35px;
            height: 35px;
            background: none;
            margin-right: 11px;
            .el-input-number {
              width: 100%;
              height: 100%;

              padding: 0;
              border: none;
              /deep/.el-input__wrapper {
                box-shadow: none;
                padding: 0;
                background: none;
                input {
                  font-size: 16px;
                  text-align: center;
                  line-height: 3rem;
                  color: #fff;
                  font-weight: bold;
                }
              }
            }
          }
          .mint-button {
            font-size: 16px;
            margin-top: 32px;
            width: 140px;
            height: 50px;
            color: #a1119f;
            font-weight: bold;
            background: none;
          }
        }

        .add {
          width: 35px;
          height: 35px;
          background: url("../assets/image/home/+.png") no-repeat;
          background-size: 100% 100%;
        }
      }
    }
  }
  .show-card {
    width: 100%;
    padding: 0 0.5rem 2rem;
    background: url("../assets/image/home/bg1.png") no-repeat 100% 100%;
    .show-border {
      width: 100%;
      background: url("../assets/image/home/bg1-border.png") no-repeat;
      background-size: 100% 108%;
      padding: 7rem 2rem 5rem 2rem;
      .show-view {
        width: 97%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .show-box {
          width: 85%;
          height: 35px;
          background: url("../assets/image/home/bg1-show-border.png") no-repeat;
          background-size: 100% 100%;
          div {
            width: 96%;
            background: rgb(0, 0, 0);
            height: 80%;
            marquee {
              height: 100%;
            }
          }
        }
        .show-text {
          margin-top: 25px;
          p {
            width: 96%;
            font-size: 14px;
            color: #73f5d6;
            margin-bottom: 3px;
            display: inline-block;
          }
          p.title {
            font-size: 18px;
            color: #76ffdd;
            margin-bottom: 8px;
            font-family: GILSANUB;
            background: none;
            font-weight: bold;
            display: block;
          }
        }
      }
    }

    .text {
      width: 95%;
      margin-top: 4rem;
    }
  }
  .content {
    width: 100%;
    padding: 1rem 0 0;
    .row {
      margin-bottom: 1rem;
      .top {
        width: 78%;
        margin-bottom: 20px;
      }
      .col {
        margin-bottom: 20px;
        .left {
          display: flex;
          flex-direction: column;
          text-align: center;
          margin-left: 0px;
          span {
            font-size: 16px;
            color: #fff;
            font-weight: bold;
            margin-bottom: 10px;
            width: 100%;
            line-height: 28px;
          }
          span.title {
            font-size: 32px;
            margin-bottom: 10px;
            margin-top: 40px;
          }
          a {
            color: #76ffdd;
            text-decoration: none;
            font-weight: bold;
            font-size: 18px;
            letter-spacing: 0.5px;
          }
        }
      }
    }
    .row.nft {
      p {
        color: #fff;
        font-size: 18px;
        width: 95%;
        margin: 0 auto;
        letter-spacing: 0.9px;
        font-weight: bold;
      }
      p.title {
        color: #76ffdd;
        font-size: 27px;
        margin: 100px auto 50px;
      }
    }

    .row.team {
      img {
        margin-bottom: 30px;
      }
      p {
        width: 80%;
        color: #fff;
        margin: 0px auto 10px;
        word-wrap: break-word;
        font-weight: bold;
      }
      p.title {
        color: #76ffdd;
        font-size: 27px;
        margin: 80px auto 50px;
      }
    }

    .row.footer {
      width: 100%;
      margin: 0;
      background: url("../assets/image/footer.png") no-repeat;
      background-size: 100% 100%;
      img {
        width: 95%;
        margin: 0 auto;
        padding: 20px 0 25px;
      }
    }
  }

  .primary {
    width: 65%;
    height: 80px;
    line-height: 0px;
    display: inline-block;
    background: url("../assets/image/home/etherscan.png") no-repeat;
    background-size: 100% 92%;
    background-position-y: 5px;
    border: none;
    cursor: pointer;
    font-size: 14px;
    letter-spacing: 1.2px;
    font-weight: bold;
    text-decoration: none;
  }
}
</style>
<style lang="less">
a.primary:hover,
.jump-link:hover {
  color: #fff !important;
}
.jump-link {
  color: #76ffdd;
  text-decoration: none;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.5px;
}
</style>
