import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
// 引入NProgress
import NProgress from "nprogress";
// 引入nprogress样式文件
import "nprogress/nprogress.css";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/airdrop",
    name: "AirdropView",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AirdropView.vue"),
  },
  {
    path: "/champion",
    name: "ChampionView",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ChampionView.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // 路由跳转后，让页面回到顶部
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  window.pageYOffset = 0;
  //next() 放行 next('/login') 强制跳转
  // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁!!
  document.getElementById("app").style.visibility = "hidden";
  NProgress.start();
  next();
});

export default router;
