<template>
  <div>
    <b-navbar class="p-0" toggleable="lg" dark>
      <b-navbar-brand class="p-0" to="/">
        <img class="logo" src="../assets/image/LOGO.png" alt="Kitten" />
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item class="ps-2 pe-2" to="/">HOME</b-nav-item>
          <li class="nav-item ps-2 pe-2">
            <a
              href="javascript:void(0)"
              class="nav-link"
              @click="goAnchor('#ABOUT')"
              >ABOUT</a
            >
          </li>
          <b-nav-item-dropdown text="GAMEPLAY">
            <b-dropdown-item to="airdrop">AIRDROP</b-dropdown-item>
            <b-dropdown-item to="champion">TOKENLINE</b-dropdown-item>
          </b-nav-item-dropdown>
          <li class="nav-item ps-2 pe-2">
            <a
              href="javascript:void(0)"
              class="nav-link"
              @click="goAnchor('#NFTs')"
              >NFTs</a
            >
          </li>
          <li class="nav-item ps-2 pe-2">
            <a
              href="javascript:void(0)"
              class="nav-link"
              @click="goAnchor('#TEAM')"
              >TEAM</a
            >
          </li>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ms-auto">
          <b-navbar-brand
            href="https://opensea.io/zh-CN/collection/azugoalnft"
            target="_blank"
          >
            <img src="../assets/image/Opensea.png" alt="Opensea" />
          </b-navbar-brand>
          <b-navbar-brand href="https://t.me/azugoalnft" target="_blank">
            <img src="../assets/image/Telegram.png" alt="Telegram" />
          </b-navbar-brand>
          <b-navbar-brand
            href="https://twitter.com/AzuGoalClub"
            target="_blank"
          >
            <img src="../assets/image/Twitter.png" alt="Twitter" />
          </b-navbar-brand>
          <b-nav-form class="justify-content-center" v-if="!isConnect">
            <b-button
              class="my-2 my-sm-0 fw-bold connect"
              @click="openWallet"
              :loading="buttonLoading"
              >Connect Wallet</b-button
            >
          </b-nav-form>
          <b-nav-item disabled class="ps-2 pe-2" v-if="isConnect">{{
            substrAddress
          }}</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <b-modal
      id="modal-center"
      centered
      title="Select Wallet"
      v-model="walletModal"
      hide-footer="true"
    >
      <div class="wallet-modal">
        <button class="wallet" @click="connect(1)">
          <img src="../assets/image/metamask.png" /><span>MetaMask</span>
        </button>
        <button class="wallet" @click="connect(2)">
          <img src="../assets/image/okx.png" /><span>OKX Wallet</span>
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { ethers } from "ethers";
import { strSubstr } from "@/filters";
import { goAnchor } from "@/utils";
import { mapState } from "vuex";
import { toRaw } from "vue";
export default {
  name: "NavBar",
  props: {},
  computed: {
    substrAddress() {
      return strSubstr(this.address);
    },
    ...mapState(["abi", "contract", "contractAddress"]),
  },
  data() {
    return {
      address: "",
      change: null,
      chain2: {
        chainId: 1, // 目标链ID
        chainName: "Ethereum Mainnet",
        nativeCurrency: {
          name: "ETH",
          symbol: "ETH",
          decimals: 18,
        },
        rpcUrls: ["https://mainnet.infura.io/v3/"], // 节点
        blockExplorerUrls: ["https://etherscan.io"],
      },
      chain1: {
        chainId: 1, // 目标链ID
        chainName: "Ethereum Mainnet",
        nativeCurrency: {
          name: "ETH",
          symbol: "ETH",
          decimals: 18,
        },
        rpcUrls: ["https://mainnet.infura.io/v3/"], // 节点
        blockExplorerUrls: ["https://etherscan.io"],
        // chainId: 80001, // 目标链ID
        // chainName: "Mumbai",
        // nativeCurrency: {
        //   name: "MATIC",
        //   symbol: "MATIC",
        //   decimals: 18,
        // },
        // rpcUrls: ["https://matic-mumbai.chainstacklabs.com"], // 节点
        // blockExplorerUrls: ["https://mumbai.polygonscan.com"],
      },
      chain: {},
      isChainInit: false,
      isConnect: false,
      buttonLoading: false,
      button: true,
      isWhiteMint: false,
      isPublicMint: false,
      isShowTime: true,
      walletModal: false,
    };
  },
  methods: {
    openWallet() {
      this.walletModal = true;
    },
    chainInit: async function (type) {
      if (typeof window.ethereum != "undefined") {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        if (type == 2) {
          this.chain = this.chain2;
        } else {
          this.chain = this.chain1;
        }
        this.chain.chainId = await ethers.utils.hexValue(this.chain.chainId);
        try {
          await provider.send("wallet_switchEthereumChain", [
            { chainId: this.chain.chainId },
          ]);
        } catch (switchError) {
          // This error code indicates that the chain has not been added to OKX Wallet.
          if (switchError.code === 4902) {
            try {
              await provider.send("wallet_addEthereumChain", [
                toRaw(this.chain),
              ]);
            } catch (addError) {
              this.$message.error(
                "The current chain does not meet the requirements. Please switch the chain ID first"
              );
              return;
              // handle "add" error
            }
          } else {
            this.$message.error(
              "The current chain does not meet the requirements. Please switch the chain ID first"
            );
            return;
          }
          // handle other "switch" errors
        }

        this.isChainInit = true;
      } else {
        this.$message.error("You need to visit the web in Dapp browser");
        return;
      }
    },
    connect: async function (type) {
      this.walletModal = false;
      if (typeof window.ethereum == "undefined") {
        this.$message.error("You need to visit the web in Dapp browser");
        return;
      }
      this.buttonLoading = true;
      const provider = new ethers.providers.Web3Provider(window.ethereum);

      try {
        let address = (await provider.send("eth_requestAccounts", []))[0];
        await this.chainInit(type);
        if (!this.isChainInit) {
          this.buttonLoading = false;
          return;
        }
        this.address = address;
        await this.login();
        await this.readMintInfo();
        this.buttonLoading = false;

        this.change = () => {
          this.logout();
        };
        setTimeout(() => {
          window.ethereum.removeListener("accountsChanged", this.change);
          window.ethereum.removeListener("chainChanged", this.change);
          window.ethereum.removeListener("disconnect", this.change);
          window.ethereum.on("accountsChanged", this.change);
          window.ethereum.on("chainChanged", this.change);
          window.ethereum.on("disconnect", this.change);
        }, 1000);
      } catch (loginError) {
        this.buttonLoading = false;
        console.log(loginError);
      }
    },
    login() {
      if (!this.address) {
        return;
      }
      this.button_msg = "Address:" + this.address;
      this.$message({
        message: "Connect Successful",
        type: "success",
      });
      this.isConnect = true;
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      var signer = provider.getSigner();
      this.$store.commit(
        "SET_Contract",
        new ethers.Contract(this.contractAddress, this.abi, signer)
      );
      this.$store.commit("SET_IsConnect", true);
      this.$store.commit("SET_Address", this.address);
    },
    readMintInfo: async function () {
      this.isPublicMint = await this.contract.isPublicSaleActive();
      this.$store.commit("SET_IsPublicMint", this.isPublicMint);
      this.isShowTime = await this.contract.isShowTimeStart();
      this.$store.commit("SET_IsShowTime", this.isShowTime);
      console.log(this.isPublicMint, this.isShowTime);
      this.$store.commit("SET_IsLoading", false);
    },
    logout() {
      window.location.reload();
    },
    goAnchor(selector) {
      if (this.$route.path != "/") {
        this.$router.push("/");
      } else {
        goAnchor(selector);
      }
    },
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.navbar {
  background: #1c0726;
}
.logo {
  width: 245px;
}
.nav-item {
  font-size: 16px;
  color: white;
  a.nav-link {
    font-size: 16px;
    color: white;
  }
  /deep/.btn-link {
    font-size: 16px;
    color: white;
    font-weight: 600;
  }
  /deep/a.dropdown-item {
    font-weight: 600;
  }
}
.connect {
  background-color: #81e1fa;
  color: #000;
  letter-spacing: 1.2px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

@media (max-width: 576px) {
  .logo {
    width: 200px;
  }
  .nav-item {
    /deep/ .btn-group {
      flex-direction: column;
    }
  }
}
</style>

<style lang="less">
.nav-item {
  a.nav-link {
    font-size: 16px;
    font-weight: 600;
    color: white;
    letter-spacing: 1.2px;
  }
}
.modal-header {
  .modal-title {
    font-weight: bold;
  }
}
.wallet-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .wallet {
    width: 60%;
    text-align: center;
    padding: 10px 0;
    border: 1px solid #e1e1e1;
    border-radius: 15px;
    margin: 10px auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    img {
      width: 12%;
    }
    span {
      display: inline-block;
      width: 65%;
      text-align: center;
      font-weight: bold;
      color: #000;
    }
  }
}
</style>
