/**
 * 使用js实现锚点滚动功能；字符串需要是'#id'锚点格式；数字的话标识要滚动的位置
 * @param {String,Number} selector
 */
export function goAnchor(selector) {
  let top = 0;
  const scrollTop =
    document.documentElement.scrollTop ||
    window.pageYOffset ||
    document.body.scrollTop;
  if (typeof selector === "number") {
    top = selector - scrollTop;
  } else {
    const anchor = document.querySelector(selector) || { offsetTop: 0 };
    top = anchor.offsetTop - scrollTop;
  }
  window.scrollBy({ top, behavior: "smooth" });
}

export function showIpfs(str) {
  //https://crustwebsites.net/ipfs/
  //https://gateway.pinata.cloud/ipfs/
  const nStr = "https://ipfs.isotop.top/ipfs/" + str.substr(7, str.length);
  return nStr;
}
